// Fetch the programme.
export async function fetchProgramme(
  programmeCode // the reference that identified this programme
) {
  // call the API and get the programme
  let result = undefined; // default result if we can't fetch anything

  // exit early if someone hasn't provided a programme code
  if (!programmeCode || programmeCode.length === 0) {
    return result;
  }

  // prepare for loading the programme 
  const apiEndpoint = process.env.REACT_APP_API_BFF_BASE || 'BUGGER:REACT_APP_API_BFF_BASE';
  
  let apiUri = apiEndpoint + '/programmes/' + programmeCode;

  console.log('Calling ' + apiUri + ' ...');

  let response = await fetch(apiUri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors' // TODO SO: ensure this is going to be a CORS call at some point
  }).then(
    response => {
      // if everything is okay, bypass the response code handling
      if (response.ok)
        return response;

      // now handle error edge cases
      switch (response.status) {
        case 404: // 404 means no programme was found
          console.log("Programme not found.");
          return undefined;
        case 409: // 409 means there is an issue with the data integrity in the API
          throw new Error('DATA INTEGRITY ERROR: ' + response.status + ': ' + response.statusText);
        default: // Other errors are not expected but UI should notify user
          throw new Error('UNEXPECTED ERROR: ' + response.status + ': ' + response.statusText);
      }
    }
  // it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components
  ).catch(err => {
    // TODO:MC - stop swallowing exceptions
    console.log(err.message);
  });

  if (!!response && response.ok) {
    const data = await response.json();
    result = {
      name: data.name,
      programmeCode: data.programmeCode,
      subjectName: data.subjectName,
      isOpenForApplication: data.isOpenForApplication,
      classes: typeof data.classes === 'undefined' || data.classes === null
        ? []
        : data.classes.sort((a, b) => a.uniqueRef.localeCompare(b.uniqueRef))
    };

    console.log("Programme loaded.");
  }

  // Done.
  return result;
}
